/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "XFce4 es un entorno de escritorio muy ligero y eso me va a venir muy bien para mi viejo portatil, es muy personalizable aunque de entrada un poco feo, el resultado puede mejorar mucho, aquí teneis un ejemplo:"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/snapshot-427.preview.png",
    alt: "",
    title: "XFce4"
  })), "\n", React.createElement(_components.p, null, "Para instalarlo en mi portatil voy a usar una instalacion limpia de Debian Lenny sin escritorio, para instalar debian sin escritorio solo tenemos que desmarcar la opción de Entorno de Escritorio cuando lo estemos instalando, ademas reduciremos el tiempo de instalación, porque el escritorio ocupa mucho espacio, como veremos XFce4 solo ocupa unos 150Mb."), "\n", React.createElement(_components.p, null, "Una vez tenemos la instalación hecha lo unicoque tenems que hacer es teclear este comando que instalará XFce4, en un momento."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "# aptitude install xorg xfce4 gdm"), "\n"), "\n", React.createElement(_components.p, null, "Ahora si xorg no nos de problemas, a veces pasa, tendremos nuestro sistema listo para funcionar."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
